import { DefaultDeserializer } from "v8";


export function SchoolRepository() {




    const apiUrl='https://api.educationtodayindia.com/educationtodayindiaschoolwebapi/api/';
    const imagePath="https://api.educationtodayindia.com/educationtodayindiaschoolwebapi/public/images/"


    // const apiUrl = 'https://educationtodayindia.com/testing/webapi/api/';
    // const imagePath = "https://educationtodayindia.com/testing/webapi/public/images/"

    //School


    const getSchoolDetBySchoolId = async (schoolinput: any) => {
        debugger;

        $("#loaderParent").show();
        try {
            debugger;
            const response = await fetch(apiUrl + 'getschoolbyschoolid', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(schoolinput)
            })
            const responseData: any = await response.json();

            $("#loaderParent").hide(); return responseData;
        } catch
        {
            $("#loaderParent").hide(); return '';
        }
    }
    const getCityByDistrict = async (data:any) => {
        $("#loaderParent").show();
        try {

            const response = await fetch(apiUrl + 'getcitiesbydistrict', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            const responseData = await response.json();
            $("#loaderParent").hide(); 
            return responseData;
        }
        catch (error) {
            $("#loaderParent").hide();
        }
    }


    const updateOrderPaymentStatus=async(rsponseData:any) =>
    {
    
      $("#loaderParent").show();
      try{
     // if (isLoading) {
       // return <div>Loading...</div>;
     // }
      const response = await fetch(apiUrl+'updateorderpaymentstatus', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed
        },
        body: (rsponseData),
      }
        );
      const data:any  = await response.json();
      
      $("#loaderParent").hide();
      return  data;
    }catch(e)
    {
      $("#loaderParent").hide();
    }
    }
    
    const getClusterByCity = async (data:any) => {

        $("#loaderParent").show();
        try {

            const response = await fetch(apiUrl + 'getclustersbycity', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            const responseData = await response.json();
            $("#loaderParent").hide(); 
            return responseData;
        }
        catch (error) {
            $("#loaderParent").hide();
        }
    }
    const getDistrictByState = async (data:any) => {

        $("#loaderParent").show();
        try {

            const response = await fetch(apiUrl + 'getdistrictsbystate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            const responseData = await response.json();
            $("#loaderParent").hide(); 
            return responseData;
        }
        catch (error) {
            $("#loaderParent").hide();
        }

    }
    
    



    const viewSchoolFees=async (SchoolId:any) => {
        $("#loaderParent").show();
        try {
        const response = await fetch(apiUrl + 'viewschoolfees', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body:JSON.stringify(SchoolId)
        })
        const responseData = await response.json();
    
        $("#loaderParent").hide(); 
        return responseData;
    
    
    }
    catch (error) {
        $("#loaderParent").hide();
    }
    
       }

       

       const saveOrder=async (orderInfo:any) => {
        $("#loaderParent").show();
        try {
        const response = await fetch(apiUrl + 'saveorder', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body:JSON.stringify( orderInfo)
        })
        const responseData = await response.json();
    
        $("#loaderParent").hide(); 
        return responseData;
    
    
    }
    catch (error) {
        $("#loaderParent").hide();
    }
    
       }


    const saveSchoolFees=async (feeInfo:any) => {
        $("#loaderParent").show();
        try {
        const response = await fetch(apiUrl + 'saveschoolfees', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body:JSON.stringify( feeInfo)
        })
        const responseData = await response.json();
    
        $("#loaderParent").hide(); 
        return responseData;
    
    
    }
    catch (error) {
        $("#loaderParent").hide();
    }
    
       }



   const saveSchool=async (concatenatedInfoString:any) => {
    $("#loaderParent").show();
    try {
    const response = await fetch(apiUrl + 'saveschool', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: concatenatedInfoString
    })
    const responseData = await response.json();

    $("#loaderParent").hide(); 
    return responseData;


}
catch (error) {
    $("#loaderParent").hide();
}

   }

    //




    const getCities = async () => {
        debugger;
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'getcities');
            const data: any = await response.json();


            $("#loaderParent").hide(); return data;


        }
        catch (error) {
            $("#loaderParent").hide();
        }
    }

    const saveCity = async (city: any) => {


        $("#loaderParent").show();
        try {
            debugger;
            const response = await fetch(apiUrl + 'savecity', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(city)
            })
            const responseData: any = await response.json();

            $("#loaderParent").hide(); return responseData;
        } catch
        {
            $("#loaderParent").hide(); return '';
        }

    }
    const deleteCitys = async (city: any) => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'deletecity', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(city),
            })
            const responseData = await response.json();
            $("#loaderParent").hide(); return responseData;
        }

        catch (error) {
            $("#loaderParent").hide();
        }
    }


    const getclusters = async () => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'getclusters');
            const data: any = await response.json();

            $("#loaderParent").hide(); return data;

        }

        catch (error) {
            $("#loaderParent").hide();
        }
    }

    const getCitys = async () => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'getcities');
            const data: any = await response.json();
            $("#loaderParent").hide(); return data;
        }
        catch (error) {
            $("#loaderParent").hide();
        }
    }


    const saveCluster = async (cluster: any) => {

        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'savecluster', {

                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(cluster)
            })
            const responseData: any = await response.json();

            $("#loaderParent").hide(); return responseData;
        } catch (error) {

            $("#loaderParent").hide(); return '';

        }
    }

    const deleteclusters = async (cluster: any) => {

        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'deletecluster', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(cluster),
            })
            const responseData = await response.json();

            $("#loaderParent").hide(); return responseData;
        }

        catch (error) {
            $("#loaderParent").hide();
        }
    }

    const getStates = async () => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'getstates');
            const data = await response.json();
            $("#loaderParent").hide(); return data;

        }
        catch (error) {
            $("#loaderParent").hide();
        }
    }

    const saveState = async (state: any) => {

        $("#loaderParent").show();
        try {
            debugger;
            const response = await fetch(apiUrl + 'savestate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(state)
            })
            const responseData = await response.json();

            $("#loaderParent").hide(); return responseData;
        }
        catch (error) {

            $("#loaderParent").hide(); return '';
        }
    }


    const deleteState = async (state: any) => {
        debugger;
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'deletestate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(state),
            })

            const responseData = await response.json();

            $("#loaderParent").hide(); return responseData;
        }

        catch (error) {
            $("#loaderParent").hide();
        }

    }

    const getDistricts = async () => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'getdistricts');
            const data = await response.json();
            $("#loaderParent").hide(); return data;
        }
        catch (error) {
            $("#loaderParent").hide();
        }
    }

    const getDistrictStates = async () => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'getstates');
            const data = await response.json();
            $("#loaderParent").hide(); return data;

        } catch (error) {
            $("#loaderParent").hide();
        }
    }

    const saveDistricts = async (district: any) => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'savedistrict', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(district)
            })
            const responseData: any = await response.json();
            $("#loaderParent").hide(); return responseData;
        }
        catch (error) {

            $("#loaderParent").hide(); return '';
        }


    }


    const deleteDistrict = async (district: any) => {
        $("#loaderParent").show();
        try {

            const response = await fetch(apiUrl + 'deletedistrict', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(district),
            })
            const responseData = await response.json();
            $("#loaderParent").hide(); return responseData;
        }

        catch (error) {
            $("#loaderParent").hide();
        }
    }


    const getResources = async (resource: any) => {
        debugger;
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'viewschoolresources', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(resource)
            })
            debugger;
            const data = await response.json();
            $("#loaderParent").hide(); return data;
        }

        catch (error) {
            $("#loaderParent").hide();
        }
    }


    const getSchool = async () => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'getschools');
            const data = await response.json();

        }

        catch (error) {
            console.error('Error fetching all options:', error);
        }
    }

    const schoolLogin = async (loginDetails: any) => {

        $("#loaderParent").show();
        try {

            debugger;
            const response = await fetch(apiUrl + 'schoollogin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loginDetails),
            })
            debugger;
            const data = await response.json();
            $("#loaderParent").hide(); return data;
        }
        catch (error) {
            $("#loaderParent").hide(); return '';

        }

    }



    const saveResource = async (resource: any) => {

        $("#loaderParent").show();
        try {

            debugger;
            const response = await fetch(apiUrl + 'saveschoolresources', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(resource),
            })
            debugger;
            const responseData = await response.json();
            $("#loaderParent").hide(); return responseData;
        }
        catch (error) {
            $("#loaderParent").hide(); return '';

        }

    }


    const uploadImage = async (formData: any) => {
        debugger;

        $("#loaderParent").show();
        try {
            debugger;
            const response = await fetch(apiUrl + 'upload', {
                method: 'POST',
                body: formData,
            });
            debugger;
            if (response.ok) {
                debugger;
                const responseData = await response.json();
                $("#loaderParent").hide(); return response;

            } else {
                console.error('Failed to upload image. Server $("#loaderParent").hide();  returned:', response.status);
            }
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    }

    const deleteResource = async (userData: any) => {
        $("#loaderParent").show();
        try {

            const response = await fetch(apiUrl + 'deleteschoolresources', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData),
            })
            debugger;
            const responseData = await response.json();

            $("#loaderParent").hide(); return responseData;
        }

        catch (error) {
            $("#loaderParent").hide();
        }
    }


    const updateUser = async (userdetails: any) => {
        debugger;
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'loginuserchangepassword', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userdetails)
            })
            const responseData = await response.json();
            $("#loaderParent").hide(); return responseData;
        }

        catch (error) {
            $("#loaderParent").hide();
        }
    }

    const saveAboutus = async (aboutus: any) => {
        debugger;

        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'saveschoolaboutus', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(aboutus)
            })
            const responseData = await response.json();
            $("#loaderParent").hide(); return responseData;
        }

        catch (error) {
            $("#loaderParent").hide();
        }
    }

    const Validateuser = async (userdetails: any) => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'userdetailsbyemail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userdetails)
            })
            const responseData = await response.json();
            $("#loaderParent").hide(); return responseData;
        }

        catch (error) {
            $("#loaderParent").hide();
        }
    }


    const saveAnnouncement = async (Announcemnts: any) => {
        $("#loaderParent").show();
        try {
            debugger;
            const response = await fetch(apiUrl + 'saveannouncements', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(Announcemnts),
            })
            debugger;
            const responseData = await response.json();
            $("#loaderParent").hide(); return responseData;
        }
        catch (error) {
            $("#loaderParent").hide(); return '';
        }
    }


    const getAnnouncements = async (Announcemnts: any) => {
        $("#loaderParent").show();
        try {
            debugger;
            const response = await fetch(apiUrl + 'getannouncements', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(Announcemnts),
            })
            debugger;
            const responseData = await response.json();
            $("#loaderParent").hide(); return responseData;
        }
        catch (error) {
            $("#loaderParent").hide();
        }
    }


    const deleteAnnouncement = async (Announcemnts: any) => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'deleteannouncements', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(Announcemnts),
            })
            const responseData = await response.json();
            $("#loaderParent").hide(); return responseData;
        }
        catch (error) {
            $("#loaderParent").hide();
        }
    }

    const saveContent = async (Content: any) => {
        $("#loaderParent").show();
        try {
            debugger;
            const response = await fetch(apiUrl + 'savecontent', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(Content),
            })
            debugger;
            const responseData = await response.json();
            $("#loaderParent").hide(); return responseData;
        }
        catch (error) {
            $("#loaderParent").hide(); return '';
        }
    }

    const saveContentSequence = async (Content: any) => {
        $("#loaderParent").show();
        try {
            debugger;
            const response = await fetch(apiUrl + 'savecontentsequence', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body:  Content,
            })
            debugger;
            const responseData = await response.json();
            $("#loaderParent").hide(); return responseData;
        }
        catch (error) {
            $("#loaderParent").hide(); return '';
        }
    }


    const saveResourceSequence = async (Content: any) => {
        $("#loaderParent").show();
        try {
            debugger;
            const response = await fetch(apiUrl + 'saveresourcesequence', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body:  Content,
            })
            debugger;
            const responseData = await response.json();
            $("#loaderParent").hide(); return responseData;
        }
        catch (error) {
            $("#loaderParent").hide(); return '';
        }
    }



    const getContents = async (Content: any) => {
        $("#loaderParent").show();
        try {
            debugger;
            const response = await fetch(apiUrl + 'getcontent', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(Content),
            })
            debugger;
            const responseData = await response.json();
            $("#loaderParent").hide(); return responseData;
        }
        catch (error) {
            $("#loaderParent").hide();
        }
    }

    const deleteContent = async (Content: any) => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'deletecontent', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(Content),
            })
            const responseData = await response.json();
            $("#loaderParent").hide(); return responseData;
        }
        catch (error) {
            $("#loaderParent").hide();
        }
    }

    return {
        saveOrder,
        updateOrderPaymentStatus,
        viewSchoolFees,
        saveSchoolFees,
        getClusterByCity,
        getCityByDistrict,
        getDistrictByState,
        saveSchool,
        imagePath, getCities, saveCity, deleteCitys, getclusters, getCitys, saveCluster, deleteclusters, getStates, saveState, deleteState, getDistricts, getDistrictStates, saveDistricts, deleteDistrict, getResources, getSchool, saveResource, uploadImage, deleteResource, updateUser, Validateuser
        , schoolLogin, getSchoolDetBySchoolId, getAnnouncements
        , saveAboutus
        , saveAnnouncement, deleteAnnouncement,
        saveContent, getContents,deleteContent,
        saveContentSequence,saveResourceSequence
    };

}